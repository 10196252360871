import '../App.css'
import NavBar from './navbar';
const Auth = ()=>{
    return(
<div className="max-w-[100vw] bga h-[96rem] ">

<NavBar />
<div>


        </div>



</div>
    )
}
export default Auth;